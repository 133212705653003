import React from "react";
function Header() {
  return (
    <header>
      <h1>Spidey Keep's</h1>
    </header>
  );
}

export default Header;
